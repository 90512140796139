$(document).ready(function(){

    if ($('.single-item').length > 0) {

        $('.single-item').slick({
            arrows: false,
            autoplay: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        });

    }

    if ($('.multiple-items').length > 0) {

        $('.multiple-items').slick({
            autoplay: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1
        });

    }

})

if (document.getElementById('validate-component') != null) {

    /**
     * 범용 폼 인증 컴포넌트
     */
    new Vue({
        el: '#validate-component',
        data: {},
        methods: {

            validateBeforeSubmit($event) {

                this.$validator.validateAll().then((result) => {

                    if (result) {
                        $event.target.submit();
                    }

                });
            },

        }
    })

}

if ($("[data-toggle='select-moving']").length > 0) {

    $("[data-toggle='select-moving']").on('change', function (event) {
        let that = $(event.target);

        let value = that.val();
        let parameter = that.data('parameter');

        let params = getAllUrlParams();

        params[parameter] = value;

        let params_string = $.param(params);

        let redirect_url = window.location.origin + window.location.pathname + "?" + params_string;

        window.location.href = redirect_url;
    })

}

new WOW().init();


// Star Rating
if (document.getElementsByClassName('star-rating') && ! $(".star-rating > .star-cb-group").hasClass("readonly-true")) {

    $(".star-rating").on("mousemove", function (event) {
        let title = $("label:hover").attr("title");
        title = title === undefined || title === "" ? "" : "[" + title + "]";
        $(".rating-text", event.delegateTarget).text(title);
    })

    $(".star-rating").on("mouseout", function (event) {
        let title = $("input:checked ~ label", event.delegateTarget).attr("title");
        title = title === undefined || title === "" ? "" : "[" + title + "]";
        $(".rating-text", event.delegateTarget).text(title);
    })

}

// Family Sites
$("#family-sites").on('change', function (event) {

    let that    = $(event.target);
    let url     = that.val();

    if (url != "") {
        window.location.href = url;
    }
})



// 그누보드 번들 팝업, 모바일에서 최적화 하기
// $(function () {
//
//     var width_break_point = 768;
//
//     var view_width = $(window).width();
//     var view_height = $(window).height();
//
//     if (view_width < width_break_point) {
//
//         var $popup_frame  = $('#hd_pop');
//         var $popups       = $('.hd_pops');
//
//         $popup_frame.css({'top': 0, 'left': 0, 'width': '100%'});
//
//         $popups.each(function (index, element) {
//             $(element).css({'top': 0, 'left': 0, 'width': '100%', 'position': 'relative', 'display': 'block'});
//             $('.hd_pops_con', element).css({'width': '100%', 'height': 'auto'})
//             $('.hd_pops_footer', element).css({'width': '100%'})
//         });
//
//         $popup_frame.css('display', 'block');
//
//     }
//
// });

// 쿠키 설정상태 확인

if (document.getElementById('check-cookie-disable-alert')) {

    if ( ! navigator.cookieEnabled)
        $('#check-cookie-disable-alert').html("<div class=\"alert alert-danger\" role=\"alert\">브라우저 쿠키 기능을 허용해주세요. <br>쿠키 기능을 활성화하지 않으면 로그인 하실 수 없습니다.</div>");

}
